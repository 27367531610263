<template>
  <div class="app-container">
    <div>
      <!-- 顶部按钮 -->
      <div class="top_btns clearfix">
        <div class="left_search fl">
          <el-button size="small" @click="setOrg()" type="success" plain>分配代理记账公司</el-button>
          <el-select size="small" @change="getList" v-model="listQuery.id" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option v-for="item in users" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
          </el-select>
          <el-input placeholder="请输入公司名称关键词" v-model="listQuery.name" style="width: 200px;" class="filter-item" @keyup.enter.native="getList" size="small" />
          <el-button class="filter-item" type="primary" size="small" plain @click="getList">搜索</el-button>
        </div>
        <div class="right_btns fr">
          <el-button size="small" @click="handleCreate2()" type="primary" plain>锁</el-button>
        </div>
      </div>
    </div>
    <!-- 顶部表 -->
    <el-table :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :key="tableKey" :data="list" border fit highlight-current-row style="width: 100%;margin-top:20px;"
      @selection-change="handleSelectionChange"
      @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="公司名" align="center" min-width="200">
        <template #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center" min-width="120">
        <template #default="scope">
          <span>{{scope.row.manager}}</span>
        </template>
      </el-table-column>
     
      <el-table-column label="状态" align="center" min-width="120">
        <template #default="scope">
          <div v-if="scope.row.isTrial != 1">
            <span v-if="scope.row.status == 1" style="color:green">正常使用</span>
            <span v-else style="color:red">已停用</span>
          </div>
          <div v-else>
            <span v-if="scope.row.status == 1" style="color:blue">试用</span>
            <span v-else style="color:red">已停用</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="签约公司" align="center" min-width="120">
        <template #default="scope">
          <el-select clearable size="small" @change="saveOrg(scope.row)" v-model="scope.row.openCom" placeholder="请选择">
            <el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="账套个数" align="center" min-width="120">
        <template #default="scope">
          <span>{{scope.row.count}}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效使用" align="center" min-width="120">
        <template #default="scope">
          <span>{{scope.row.used}}</span>
        </template>
      </el-table-column>
      <el-table-column label="本月计提数" align="center" min-width="120">
        <template #default="scope">
          <span>{{scope.row.taxUsed}}</span>
        </template>
      </el-table-column>

      <el-table-column label="账套启用日期" align="center" min-width="120">
        <template #default="scope">
          <span v-if="!scope.row.countTime.match('0001')">{{$parseTime(scope.row.countTime, "{y}-{m}-{d}")}}</span>
          <span v-else>暂未设置</span>
        </template>
      </el-table-column>

    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

    <!-- 锁弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="提示" v-model="dialogVisible" width="30%" >
      <div style="text-align: right;">
        <el-button type="danger" @click='delallClick' size="small" style="margin-bottom: 10px;">批量删除</el-button>
      </div>
      <el-table ref="multipleTable" :data="LockList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="姓名" width="360">
          <template #default="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" width="120">
          <template  #default="scope">
            <el-button size="small" type="danger" @click.native.prevent="deleteRow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <orgToUser ref="orgToUser" :show=showHide @closeSubject=closeOrg></orgToUser>
  </div>
</template>

<script>
import { getHelpOrg,getHelpUsers } from "@/api/help.js";
import { getLockList,delLock } from "@/api/org.js";

// import orgToUser from "./common/orgToUser";
import orgToUser from "./components/orgToUser.vue"
export default {
  name: "kefuOrgList",
  components: {
    orgToUser
  },
  data() {
    return {
      dialogTableVisible: false,
      list2:[],
      citys: [],
      fullscreenLoading: false,
      isLeader: 0,
      list: [],
      LockList:[],
      MobileList:[],
      total: 0,
      listQuery: {
        // disCode: "",
        id: 0,
        page: 1,
        limit: 20,
        openCom:'',
      },
      companys:[
        {
          label:'青岛公司',
          value:'青岛公司',
        },{
          label:'北京公司',
          value:'北京公司',
        },
      ],
      contentStyleObj:{}, //高度变化
      users: [],
      pilsuo: [],
      showHide: false,
      switchValue: false,
      dialogFormVisible: false,
      dialogVisible: false,
      dialogVisible3:false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      city:[]
    };
  },
  created() {
    // 获取表格高度
    this.contentStyleObj=this.$getHeight(286)
    this.getList();
  },
  methods: {
    setOrg(){
      // this.showHide = true
      this.$refs.orgToUser.underwear()
    },
    getList() {
      getHelpUsers({}).then(res=>{
        if(res.data.msg == "success") {
          this.users = res.data.data.list
        }
      })
      this.listLoading = true
      getHelpOrg(this.listQuery).then(res => {
        this.total = res.data.data.total;
        this.list = res.data.data.list;
        this.listLoading = false;
      });
    },
    // 锁
    handleCreate2(){
      this.dialogVisible = true
      this.LockListControl()
    },
    LockListControl(){
      getLockList({}).then(res=>{
        this.LockList = res.data.data ? res.data.data : []
      })
    },
    handleSelectionChange(val) {
      // pilsuo
      val.map(item => {
        this.pilsuo.push({name: item})
      })
    },
    delallClick() {
      this.$confirm(`确认删除该公司么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delLock(this.pilsuo).then((res) => {
          if(res.data.msg == 'success'){
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.LockListControl()
          }
        })
      })
    },
    //删除锁
    deleteRow(row) {
      this.$confirm(`确认删除该公司么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delLock([{name:row}]).then((res) => {
          if(res.data.msg == 'success'){
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.LockListControl()
          }
        })
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.top_btns {
  .left_search{
    float: left;
    width: 70%;
  }
  .right_btns {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.weight{
  font-weight: 600;
}
.bottom_total{
  background: #eee;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 34px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 34px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
    text-align: center;
  }
  input{
    width: 100%;
    line-height: 34px;
    padding: 0 6px;
    font-size: 14px;
  }
}
.bookkeeping-table span{
  font-size: 14px;
}
.filter-container{
  margin-bottom: 0 !important;
}
.top_nav {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.amod_ad {
  animation: ad 0.3s 0s both;
}
.amod_more {
  animation: more 0.3s 0s both;
}
.amod {
  // margin-top: 60%;
  height: 30px;
}
@-webkit-keyframes ad {
  0% {
    height: 180px;
  }

  100% {
    height: 30px;
  }
}
@-webkit-keyframes more {
  0% {
    height: 30px;
  }

  100% {
    height: 180px;
  }
}
.top_table {
  width: 100%;
  margin: 0 auto;
  background: #f0f2f6;
  height: 180px;
  overflow: hidden;
  // padding: 40px 0;
  text-align: center;
  position: relative;
  .more {
    width: 60px;
    line-height: 30px;
    height: 30px;
    transition: 0.3s;
    color: #666;
    position: absolute;
    bottom: 0;
    right: 20px;
    background: #fff;
    padding: 4px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px 5px 0 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    cursor: pointer;
  }
  .status_tag {
    width: 70px;
    height: 26px;
    line-height: 26px;
    // display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f15a24;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 50px;
  }
  .common_box {
    display: inline-block;
    width: 28%;
    height: 100px;
    background: #fff;
    padding: 10px;
    line-height: 80px;
    color: #333;
    position: relative;
    margin: 40px auto;
    cursor: pointer;
  }
  h5 {
    float: left;
    font-size: 18px;
    font-weight: normal;
    margin-left: 35%;
  }
  .left_product {
    // display: inline-block;
    margin-right: 8%;
    .main_content {
      width: 100%;
      margin: 0 auto;
    }
  }
}
</style>
