<template>
  <el-select :disabled="status" @change="changeValue" v-model="type" placeholder="选择地区" size="small" clearable>
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.name"
      :value="item.code"  size="small" >
    </el-option>
  </el-select>
</template>

<script>
import { init } from 'events'
import { getAddress } from "@/api/company"
export default {
  name: 'select',
  props: {
    status: false,
    options: Array,
    type: ''
  },
  data() {
    return {
      options:[]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      getAddress({}).then(res=>{
        if(res.data.data.list){
          this.options = res.data.data.list
        }
      })
    },
    changeValue() {
      this.$emit('update:type', this.type)
      this.$emit("success",this.type)
    },   
  }
}
</script>

<style scoped>

</style>
<!-- 参数type  回调方法select -->